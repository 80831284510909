<template>
    <div>
        <section>
            <div
                ref="wrapperProduct"
                class="flex flex-col lg:flex-row items-start gap-10 md:gap-24 mx-3.5 lg:mx-12.5 mt-12 lg:mt-16"
            >
                <!-- Col Thumbnail -->
                <div class="w-full lg:w-1/2 space-y-4 order-2 lg:order-1">
                    <div class="relative z-1" v-for="(image, index) in listImageShow">
                        <NuxtImg
                            v-if="listImageShow.length > 0 && index == 0 && !statusShowMore"
                            :src="getThumbnail(image, 1000, true)"
                            width="1000"
                            sizes="sm:100vw md:50vw lg:1000px"
                            loading="lazy"
                            class="w-full h-[calc(80vh-88px)] object-cover relative z-1"
                        />
                        <NuxtImg
                            v-if="listImageShow.length > 0 && statusShowMore"
                            :src="getThumbnail(image, 1000, true)"
                            width="1000"
                            sizes="sm:100vw md:50vw lg:1000px"
                            loading="lazy"
                            class="w-full h-full object-cover relative z-1"
                        />
                        <div
                            v-if="listImageShow.length > 1 && index == 0 && !statusShowMore"
                            @click="statusShowMore = !statusShowMore"
                            class="relative z-10 w-full h-20 cursor-pointer group duration-200"
                        >
                            <div class="bg-shadow-show-more w-full h-full col-flex">
                                <div class="flex items-center gap-2">
                                    <p class="text-lg text-blacks-90 group-hover:text-blacks-50">Xem thêm</p>
                                    <i
                                        class="i-ic:twotone-keyboard-arrow-down text-lg text-blacks-90 group-hover:text-blacks-50"
                                    ></i>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="listImageShow.length > 1 && index == listImageShow.length - 1 && statusShowMore"
                            @click="statusShowMore = !statusShowMore"
                            class="relative w-full h-20 cursor-pointer group duration-200 z-10"
                        >
                            <div @click="handleCompactListImg" class="bg-shadow-show-more w-full h-full col-flex">
                                <div class="flex items-center gap-2">
                                    <p class="text-lg text-blacks-90 group-hover:text-blacks-50">Thu gọn</p>
                                    <i
                                        class="i-ic:twotone-keyboard-arrow-down text-lg transform rotate-180 text-blacks-90 group-hover:text-blacks-50"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!----------------------------- Col InfoProduct ------------------------------->
                <div class="w-full lg:w-1/2 order-1 lg:order-2">
                    <GlobalBreadcrumb :breadcrumb="breadcrumb" isLight />
                    <h1 class="text-4xl lg:text-44px text-blacks-100 py-3 leading-12 lg:leading-14">
                        {{ product?.title }}
                    </h1>
                    <p class="text-sm lg:text-xl font-medium text-blacks-70">{{ currentProduct?.sku }}</p>
                    <div v-if="currentProduct?.price" class="flex items-start gap-2 md:gap-3 pt-6 xl:pt-8">
                        <p class="text-2xl font-medium text-blacks-100">{{ currentProduct?.price }}</p>
                        <p class="font-medium">VNĐ</p>
                    </div>
                    <p
                        v-if="
                            currentProduct?.iconTitle ||
                            (product?.attributes && product?.attributes?.color && product?.attributes?.color.length > 0)
                        "
                        class="space-x-2 lg:space-x-4 pt-6 xl:pt-8"
                    >
                        <span class="text-base lg:text-xl text-black-100">Màu</span>
                        <span class="text-sm lg:text-lg text-blacks-50">{{ currentProduct?.iconTitle }}</span>
                    </p>
                    <!-- <pre>{{ product }}</pre> -->
                    <div
                        v-if="
                            (product?.attributes?.color &&
                                product?.attributes?.color.length == 0 &&
                                !currentProduct?.iconId) ||
                            (!product?.color && !product?.attributes)
                        "
                        class="w-8.5 h-8.5 b-0.5 b-gray-400 col-flex bg-white box-not-color mt-5"
                    ></div>
                    <div v-if="!product?.attributes?.color && currentProduct?.iconId" class="pt-5">
                        <div class="color-select-product w-8.5 h-8.5 cursor-pointer b-0.5 b-gray-400 active">
                            <img :src="getThumbnail(currentProduct?.iconId, 100, true)" alt="Icon Color" />
                        </div>
                    </div>
                    <div
                        v-if="
                            product?.attributes && product?.attributes?.color && product?.attributes?.color.length > 0
                        "
                        class="flex items-center gap-3 pt-5"
                    >
                        <div
                            @click="changeCurrentColor(item?.icon)"
                            v-for="item in product?.attributes?.color"
                            :class="currentProduct?.iconId == item?.icon ? 'active' : ''"
                            class="color-select-product w-8.5 h-8.5 cursor-pointer b-0.5 b-gray-400"
                        >
                            <img :src="getThumbnail(item?.icon, 100, true)" alt="Icon Color" />
                        </div>
                    </div>
                    <p v-if="activeSize?.title" class="space-x-4 pt-6 xl:pt-8">
                        <span class="text-base lg:text-xl text-blacks-100">Kích thước</span>
                        <span class="text-sm lg:text-lg text-blacks-50">{{ activeSize?.title }}</span>
                    </p>
                    <div
                        v-if="
                            (currentProduct?.list_size &&
                                currentProduct?.list_size.length > 1 &&
                                currentProduct?.list_size[0]?.size) ||
                            (currentProduct?.list_size && currentProduct?.list_size.length > 1)
                        "
                        class="flex items-center gap-3 pt-5 flex-wrap"
                    >
                        <div
                            v-if="
                                currentProduct?.list_size &&
                                currentProduct?.list_size.length > 1 &&
                                currentProduct?.list_size[0]?.size
                            "
                            @click="changeCurrentSize(item?.size, item.product)"
                            v-for="(item, index) in currentProduct?.list_size"
                            :class="
                                item?.size?.id == activeSize?.id
                                    ? 'b-blacks-90 text-blakcs-90'
                                    : 'b-blacks-30 text-blacks-30'
                            "
                            class="focus:bg-red-500 px-2 lg:px-3 py-1.5 lg:py-2.5 cursor-pointer b-1"
                        >
                            <p class="text-xs lg:text-sm">{{ item?.size?.title }}</p>
                            <!-- <p class="text-sm">{{ item?.size?.id }}</p> -->
                        </div>
                        <div
                            v-if="
                                !product?.attributes &&
                                !currentProduct?.list_size[0]?.size &&
                                currentProduct?.list_size.length > 0 &&
                                activeSize?.title
                            "
                            class="focus:bg-red-500 px-2 lg:px-3 py-1.5 lg:py-2.5 cursor-pointer b-1 b-blacks-90 text-blakcs-90"
                        >
                            <p class="text-xs lg:text-sm">{{ currentProduct?.list_size[0]?.title }}</p>
                        </div>
                    </div>
                    <!----------------------------- Col InfoProduct ------------------------------->

                    <div>
                        <div
                            @click="addProductToCart"
                            class="btn-effect-light w-full b-1 b-blacks-90 text-blacks-100 text-lg font-medium py-3 xl:py-5 text-center mt-8 cursor-pointer"
                        >
                            Thêm vào giỏ hàng
                        </div>
                        <nuxt-link
                            :to="{ name: 'shopping_cart' }"
                            @click="addProductToCart"
                            class="btn-effect-dark w-full b-1 b-blacks-90 text-white bg-blacks-100 text-lg font-medium py-3 xl:py-5 text-center cursor-pointer mt-4 block"
                        >
                            Liên hệ để mua hàng
                        </nuxt-link>
                    </div>
                    <p class="text-sm lg:text-base text-blacks-70 pt-6 pb-5">
                        {{ product?.description }}
                    </p>
                    <div v-if="product?.prominent_features" class="b-t-1 b-t-blacks-10 py-3 lg:py-5" v-auto-animate>
                        <div
                            @click="showInfoFeature = !showInfoFeature"
                            class="flex items-center justify-between cursor-pointer"
                        >
                            <p class="text-xl text-blacks-100">Tính năng nổi bật</p>
                            <i v-if="showInfoFeature" class="i-ic:baseline-minus text-xl"></i>
                            <i v-else class="i-ic:baseline-add text-xl"></i>
                        </div>

                        <div
                            v-if="showInfoFeature"
                            class="text-blacks-90 mt-6 content-list"
                            v-html="currentProduct?.prominent_features"
                        ></div>
                    </div>
                    <div v-if="product?.specifications_link" class="b-t-1 b-t-blacks-10 py-3 lg:py-5" v-auto-animate>
                        <div
                            @click="showInfoSpecifications = !showInfoSpecifications"
                            class="flex items-center justify-between cursor-pointer"
                        >
                            <p class="text-xl text-blacks-100">Thông số kỹ thuật</p>
                            <i v-if="showInfoSpecifications" class="i-ic:baseline-minus text-xl"></i>
                            <i v-else class="i-ic:baseline-add text-xl"></i>
                        </div>
                        <div v-if="showInfoSpecifications" class="mt-5">
                            <a
                                v-if="product?.specifications_link"
                                :href="product?.specifications_link"
                                download
                                class="flex items-center gap-3"
                            >
                                <i class="i-ic:outline-file-download text-2xl text-blacks-70"></i>
                                <p class="text-lg text-blacks-90">Tải bảng thông số kỹ thuật</p>
                            </a>
                            <div class="content-list-custom" v-html="product?.specifications"></div>
                        </div>
                    </div>
                    <div
                        v-if="product?.guide_links && product?.guide_links.length > 0"
                        class="b-t-1 b-t-blacks-10 py-3 lg:py-5"
                        v-auto-animate
                    >
                        <div
                            @click="showInfoInstructions = !showInfoInstructions"
                            class="flex items-center justify-between cursor-pointer"
                        >
                            <p class="text-xl text-blacks-100">Hướng dẫn lắp đặt</p>
                            <i v-if="showInfoInstructions" class="i-ic:baseline-minus text-xl"></i>
                            <i v-else class="i-ic:baseline-add text-xl"></i>
                        </div>
                        <div v-if="showInfoInstructions" class="mt-5">
                            <div class="flex flex-col lg:flex-row items-start gap-4 lg:gap-2 mt-6">
                                <div class="w-full lg:w-1/2">
                                    <p class="text-blacks-90 font-bold mb-3">Hướng dẫn</p>
                                    <div class="space-y-4">
                                        <a
                                            :href="item?.link + '?download'"
                                            v-for="(item, index) in product?.guide_links"
                                            :key="index"
                                            target="_blank"
                                            :download="item?.title"
                                            class="flex items-center gap-3"
                                        >
                                            <i class="i-ic:outline-file-download text-2xl text-blacks-70 flex-none"></i>
                                            <p class="text-base lg:text-lg text-blacks-90">
                                                {{ item?.title ?? textDownloadGuide }}
                                                {{ !item?.title ? index + 1 : '' }}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                                <div class="w-full lg:w-1/2">
                                    <p class="text-blacks-90 font-bold mb-3">Thông tin hỗ trợ</p>
                                    <div
                                        class="text-blacks-90 space-y-2 content-list"
                                        v-html="product?.support_info"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <SectionRelated
            v-if="relatedProducts && relatedProducts.length > 0"
            :title="'Gợi ý sản phẩm liên quan'"
            :data="relatedProducts"
        />
        <SectionCollectionBottom :data="product?.collection" />

        <SectionSuggestion
            v-if="listProductViewed && listProductViewed.length > 0"
            :title="'Sản phẩm đã xem'"
            :data="listProductViewed"
        />
    </div>
</template>

<script setup lang="ts">
import SectionSuggestion from './SectionSuggestion.vue'
import SectionRelated from './SectionRelated.vue'
import SectionCollectionBottom from './SectionCollectionBottom.vue'
import { useGetShoppingCart } from '../../stores/useShoppingCart'
import nuxtStorage from 'nuxt-storage'
const storeShopping = useGetShoppingCart()
const showInfoFeature = ref(true)
const showInfoSpecifications = ref(true)
const showInfoInstructions = ref(true)
const slug = useRoute().params.slug
const globalSettings = inject('globalSettings')

const { getProducts, getRelatedProducts } = useProducts(globalSettings)
const product = await getProducts({ slug: slug })
const productViewed = {
    id: product?.id,
    url: product?.url,
    title: product?.title,
    description: product?.description,
    thumbnail: product?.attributes?.color.length > 0 ? product?.attributes?.color[0].images[0] : product?.images[0]
}
const setProduct = ref(getStorage('viewed_products') ? getStorage('viewed_products') : [])
const listProductViewed = computed(() => setProduct.value.filter((item: any) => item?.id !== product?.id))
const relatedProducts = ref([])
const textDownloadGuide = 'Tải hướng dẫn lắp đặt '
const activeSize = ref()
const currentProduct = ref()
const listImageShow = computed(() => {
    return currentProduct.value?.images
})
const statusShowMore: any = ref(false)
const wrapperProduct = ref<HTMLElement | any>(null)
const productAdded = computed(() => {
    return {
        idCollection: product?.collection,
        idCategory: product?.category,
        uniqueId: `${product?.id}-${currentProduct.value?.iconId}-${activeSize.value?.id}`,
        title: product?.title,
        url: product?.url,
        ...currentProduct.value,
        ...{ size: activeSize.value }
    }
})
const breadcrumb = ref([
    {
        title: 'Trang chủ',
        url: '/'
    },
    {
        title: 'Sản phẩm',
        url: '/loai-san-pham/' + product?.category?.slug ?? '/loai-san-pham'
    },
    {
        title: product?.title
    }
])
const handleCompactListImg = () => {
    wrapperProduct.value.scrollIntoView({ behavior: 'smooth' })
}
const setProductAdded = ref(getStorage('shopping_product') ? getStorage('shopping_product') : [])
const changeCurrentColor = (icon: string) => {
    return product?.attributes?.color?.find((item: any) => {
        if (item?.icon == icon) {
            currentProduct.value.id = item?.variants[0]?.product?.id
            currentProduct.value.iconId = item?.icon
            currentProduct.value.iconTitle = item?.title
            currentProduct.value.images = item?.variants[0]?.product?.images
            currentProduct.value.quick_detail = item?.quick_detail
            currentProduct.value.sku = item?.sku
            currentProduct.value.price = item?.price
            currentProduct.value.list_size = item?.variants
            currentProduct.value.prominent_features = item?.variants[0]?.product?.prominent_features
        }
        if (currentProduct.value.list_size[0]?.size) {
            activeSize.value = currentProduct.value.list_size[0]?.size
        } else {
            activeSize.value = ''
        }
    })
}
const changeCurrentSize = (size: object, sizeProduct: object) => {
    activeSize.value = size
    currentProduct.value.prominent_features = sizeProduct?.prominent_features
    return currentProduct.value?.list_size?.find((item: any) => {
        if (item?.size?.id == size?.id) {
            currentProduct.value.id = sizeProduct.id
            currentProduct.value.images = item?.product?.images
            currentProduct.value.quick_detail = item?.product?.quick_detail
            currentProduct.value.sku = item?.product?.sku
            currentProduct.value.price = item?.product?.price
        }
    })
}
const addProductToCart = (product: object) => {
    storeShopping.isOpenPopupCart = false
    const checkExistProduct = setProductAdded.value
        .map((product: any) => product.uniqueId)
        .some((item: any) => item == productAdded.value.uniqueId)
    if (setProductAdded.value.length > 0 && checkExistProduct) {
        setProductAdded.value.filter((item: any) => {
            if (item.uniqueId == productAdded.value.uniqueId) {
                if (!item.quantity) {
                    item.quantity = 1
                }
                item.quantity += 1
            }
        })
        nuxtStorage.localStorage.setData('shopping_product', setProductAdded.value, 30, 'd')
    } else {
        productAdded.value.quantity = 1

        setProductAdded.value.push(productAdded.value)
        nuxtStorage.localStorage.setData('shopping_product', setProductAdded.value, 30, 'd')
    }

    // nuxtStorage.localStorage.setData('shopping_product', setProductAdded.value, 30, 'd')
    storeShopping.numberProduct = setProductAdded.value
    setTimeout(() => {
        storeShopping.isOpenPopupCart = true
    }, 300)
}
onMounted(async () => {
    if (
        (product?.attributes?.color.length > 0 && product?.attributes?.size.length > 0) ||
        (product?.attributes?.color.length > 0 && product?.attributes?.size.length <= 0)
    ) {
        const productDefault = product?.attributes?.color[0]

        activeSize.value = productDefault.variants[0]?.size ?? null
        currentProduct.value = {
            id: productDefault.variants[0]?.product?.id,
            iconId: productDefault.icon ?? '',
            iconTitle: productDefault.title ?? '',
            sku: productDefault.sku ?? '',
            images: productDefault?.variants[0]?.product?.images ?? [],
            price: productDefault.price ?? '',
            quick_detail: productDefault.quick_detail ?? '',
            list_size: productDefault.variants ?? [],
            prominent_features: productDefault?.variants[0]?.product?.prominent_features
        }
    } else if (product?.attributes?.color.length <= 0 && product?.attributes?.size.length > 0) {
        const productDefault = product?.attributes?.size[0]
        const listSize = product?.attributes?.size.map((item) => {
            return {
                size: item.size,
                product: item?.variants[0].product
            }
        })
        productViewed.thumbnail = productDefault.images[0]
        activeSize.value = listSize[0].size ?? null

        currentProduct.value = {
            id: productDefault.variants[0]?.product?.id,
            iconId: '',
            iconTitle: '',
            sku: productDefault.sku ?? '',
            images: productDefault.images ?? [],
            price: productDefault.price ?? '',
            quick_detail: productDefault.quick_detail ?? '',
            list_size: listSize ?? [],
            prominent_features: productDefault?.variants[0]?.product?.prominent_features
        }
    } else {
        const productDefault = product
        currentProduct.value = {
            id: productDefault?.id,
            iconId: productDefault?.color?.icon ?? '',
            iconTitle: productDefault?.color?.title ?? '',
            sku: productDefault.sku ?? '',
            images: productDefault.images ?? [],
            price: productDefault.price ?? '',
            quick_detail: productDefault.quick_detail ?? '',
            list_size: [productDefault?.size] ?? [],
            prominent_features: productDefault?.prominent_features
        }
        activeSize.value = currentProduct.value.list_size[0] ?? null
    }
    nuxtStorage.localStorage.setData('viewed_products', [productViewed], 24, 'h')
    if (setProduct.value.length > 0) {
        const checkIdExist = setProduct.value.map((item) => item.id)
        if (!checkIdExist.includes(product?.id)) {
            setProduct.value.push(productViewed)
        }
    } else {
        setProduct.value.push(productViewed)
    }
    nuxtStorage.localStorage.setData('viewed_products', setProduct.value, 24, 'h')
    if (product?.related_products && product?.related_products.length > 0) {
        relatedProducts.value = product?.related_products
    } else {
        relatedProducts.value = await getRelatedProducts({
            slug: slug,
            collection_ids: product?.collection?.id,
            category_ids: product?.category,
            query: { limit: 4 }
        })
    }
})
useDirectusCollectionSeo('news', {
    ...product,
    seo_detail: {
        ...product?.seo_detail
    }
})
</script>

<style>
.color-select-product.active {
    @apply b-2 b-blacks-90 shadow-xl relative;
}
.color-select-product.active:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid white;
}
.content-list ul {
    @apply list-disc space-y-3;
}
.content-list ul li {
    @apply ml-4 text-sm lg:text-base;
}
.content-list-custom ul {
    @apply space-y-3;
}
.content-list-custom ul li ul {
    @apply list-disc;
}

.box-not-color {
    @apply relative overflow-hidden bg-gray-50/70;
}
.box-not-color:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 150%;
    @apply bg-gray-200;

    transform: rotate(45deg);
}
.box-not-color:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 150%;
    @apply bg-gray-200;
    transform: rotate(-45deg);
}
.bg-shadow-show-more {
    background: linear-gradient(to top, rgba(255, 255, 255, 0.1) 1%, rgba(215, 215, 209, 0.1) 100%);
}
</style>
